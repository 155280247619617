<template>
  <div class="">
    <header class="mch__form-header">
      <div class="mch__form-title">{{ $t("piggybank.payout.summary") }}</div>
    </header>

    <main class="mch-confirma">
      <dl class="mch-confirma__list mch-confirma__list--inline">
        <dt>{{ $t("piggybank.payout.summaryAmount") }}</dt>
        <dd>{{ formatMoney(internalAmount) }} €</dd>

        <dt>{{ $t("piggybank.payout.summaryPrice") }}</dt>
        <dd>{{ formatMoney(transferFee) }} €</dd>

        <!-- <dt>{{ $t("piggybank.payout.summaryFee") }}</dt>
        <dd>{{ formatMoney(serviceFee) }} €</dd> -->

        <dt class="fw-bold">{{ $t("piggybank.payout.summaryTotal") }}</dt>
        <dd class="fw-bold">{{ formatMoney(totalReceived) }} €</dd>
      </dl>
      <div class="mch-confirma__info">
        <ul>
          <li>{{$t("piggybank.payout.summaryFeeDescription1")}}</li>
          <!-- <li>{{$t("piggybank.payout.summaryFeeDescription2")}}</li> -->
        </ul>
      </div>
    </main>

    <footer class="mch__actions">
      <button @click="backStep" class="button button--secondary button--icoStart">
        <i class="uil uil-arrow-left"></i> {{ $t("generic.back") }}
      </button>
      <bbButton @click="submitPayout" :label="$t('piggybank.createModal.confirmData')" :loading="loadingTransfer"
                class="button button--primary button--block" />
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import bbButton from "../../../components/buttons/bbButton.vue";

export default {
  name: "HuchaTransferirCuentaStep2",
  components: {
    bbButton
  },
  data() {
    return {
      internalAmount: 0,
      loadingTransfer: false
    };
  },
  created() {
    this.internalAmount = parseFloat(this.amount);
  },
  watch: {
    amount(newValue) {
      this.internalAmount = parseFloat(newValue);
    }
  },
  props: {
    amount: {
      type: Number,
      default: 0
    },
    payouts: Array
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    serviceFee() {
      //return this.internalAmount * 0.02;
      return this.internalAmount * 0.00;
    },
    transferFee() {
      return this.payouts.length >= 3 ? 3 : 0;
    },
    totalReceived() {
      return this.internalAmount - this.serviceFee - this.transferFee;
    }
  },
  methods: {
    backStep() {
      this.$emit("next-step", 1);
    },
    nextStep() {
      this.$emit("next-step", 3);
    },
    formatMoney(amount) {
      return amount.toFixed(2);
    },
    async submitPayout() {
      this.loadingTransfer = true;
      const amountInCents = Math.round(this.internalAmount * 100);
      const accountId = this.user.stripeAccountId;
      const payload = {
        accountId: accountId,
        amount: amountInCents,
        currency: "eur"
      };

      let options = {
        headers: {
          "Content-Type": "application/json",
          "x-functions-key": process.env.STRIPE_HBB_API_KEY
        }
      };

      try {
        const response = await axios.post(process.env.STRIPE_HBB_API_URL + "/payout", payload, options);

        if (response.status === 200) {
          console.log("Transferencia exitosa:", response.data);
          return this.nextStep();
        } else {
          console.error("Error en la transferencia:", response.status, response.data);
        }
      } catch (error) {
        console.error("Error al realizar la solicitud:", error);
      }
    }
  }
};
</script>
