<template>
  <aside class="psteps">
    <div
      class="psteps__step"
      v-for="item in steps"
      :key="item.step"
      v-if="!shouldHideStep(item)"
      :class="[
        'psteps__step--' + item.step,
        item.step == currentStep ? 'psteps__step--current' : '',
      ]"
    >
      <!-- {{ currentStep }} - {{ item.step }} -->
      <template v-if="item.step == currentStep">
        <div class="psteps__h">
          <div class="psteps__number psteps__number--current">{{ item.step }}</div>
        </div>
        <div class="psteps__name">{{ item.name }}</div>
      </template>
      <template v-else-if="item.step > currentStep">
        <div class="psteps__h">
          <div class="psteps__number psteps__number--next"></div>
        </div>
        <div class="psteps__name psteps__name--next">{{ item.name }}</div>
      </template>
      <template v-else-if="item.step < currentStep">
        <div class="psteps__h">
          <div class="psteps__number psteps__number--back">{{ item.step }}</div>
        </div>
        <div class="psteps__name psteps__name--back">{{ item.name }}</div>
      </template>
      </div>
  </aside>
</template>


<script>
export default {
  props: {
    steps: Array,
    currentStep: Number,
  },
  methods: {
    checkResponsive() {
      return window.innerWidth <= 768; // puedes ajustar el breakpoint
    },
    shouldHideStep(item) {
      if (!this.checkResponsive()) return false;

      const step = item.step;
      const cs = this.currentStep;

      const hideRules = [
        cs === 1 && step === 4,
        cs === 2 && step === 4,
        cs === 3 && step === 1,
        cs === 4 && step === 1,
      ];

      return hideRules.some(Boolean);
    },
  },
};
</script>

<style lang="scss" scoped></style>
