<template>
  <section class="list-hucha-empty">
    <div class="list-hucha-empty__title">{{ $t("piggybank.empty") }}</div>
    <span class="link link--underline link--bold" @click="showModal = true">
      {{ $t("piggybank.howDoesItWork") }}
    </span>
    <ModalDialog
      :show="showModal"
      @close="showModal = false"
      target-class="modal__card--max"
    >
      <div class="modal-article">
        <div class="modal-article__header">{{ $t("piggybank.howDoesMyPiggibankWork") }}</div>
        <div class="modal-article__araticle">
          <div class="post-entry">
            <h4>{{ $t("piggybank.whatIsIt") }}</h4>
            <p>
              {{ $t("piggybank.whatIsIt1") }}
            </p>

            <p>
              {{ $t("piggybank.whatIsIt2") }}
            </p>

            <p>
              {{ $t("piggybank.whatIsIt3") }}
            </p>
            <h4>{{ $t("piggybank.howMuchIsIt") }}</h4>
            <p v-html="$t('piggybank.howMuchIsIt1')"></p>
            <ul>
              <li>{{ $t("piggybank.howMuchIsIt11") }}</li>
              <li>{{ $t("piggybank.howMuchIsIt12") }}</li>
              <li>{{ $t("piggybank.howMuchIsIt13") }}</li>
            </ul>
            <p>
              {{ $t("piggybank.howMuchIsIt2") }}
            </p>
            <ul>
              <li>
                {{ $t("piggybank.howMuchIsIt21") }}
              </li>
              <li>{{ $t("piggybank.howMuchIsIt22") }}</li>
            </ul>
            <!-- <p>{{ $t("piggybank.howMuchIsIt3") }}</p>
            <p>{{ $t("piggybank.howMuchIsIt4") }}</p>
            <ul>
              <li>{{ $t("piggybank.howMuchIsIt41") }}</li>
              <li>{{ $t("piggybank.howMuchIsIt42") }}</li>
            </ul> -->
            <p>{{ $t("piggybank.howMuchIsIt5") }}</p>
          </div>
        </div>
      </div>
    </ModalDialog>
  </section>
</template>

<script>
import ModalDialog from "../../../components/ui/ModalDialog.vue";
import bbButton from "../../../components/buttons/bbButton.vue";
export default {
  name: "HuchaVacia",
  components: {
    ModalDialog,
  },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>
